export const environment = {
			production: false,
			apiEnvironment: 'Dev',
			apiRoot: 'https://api.trbportal.com',
			clientApiRoot: 'http://localhost:4200',
			nodeApiRoot: 'https://api.cleanbranch4.lcross.sandbox.internal.trb.bank',
			nodeReportingApiRoot: 'https://reportingapi.cleanbranch4.lcross.sandbox.internal.trb.bank',
			azureAuthClientId: '084b0b0e-b354-4329-8a32-b187fd7474e9',
			azureAuthority: 'https://login.microsoftonline.com/9b5fd48d-145a-4add-ad02-910d2f6f7bc4',
			azureRedirectUri: 'https://internal4.lcross.sandbox.internal.trb.bank',
			fileStorageBucket: 'cleanbranch4.lcross.sandbox.internal.trb.bank-filestorage',
			webSocketApiUri: 'wss://websocket.cleanbranch4.lcross.sandbox.internal.trb.bank/'
		};
		